<template>
<div class="boxed_wrapper">

  <top-bar />

  <nav-bar />

  <div class="inner-banner has-base-color-overlay text-center" style="background: url(/assets/images/background/4.jpg);">
    <div class="container">
      <div class="box">
        <h1> {{ $t('ABOUT') }} </h1>
      </div>
    </div>
  </div>
  <div class="breadcumb-wrapper">
    <div class="container">
      <div class="pull-left">
        <ul class="list-inline link-list">
          <li>
            <a href="/"> {{ $t('HOME') }} </a>
          </li>
          <li>
            {{ $t('ABOUT') }}
          </li>
        </ul>
      </div>
      <div class="pull-right">
      </div>
    </div>
  </div>

  <section class="about sec-padd2">
    <div class="container">
      <div class="section-title center">
        <h2> {{ $t('_WORDS_ABOUT_US') }} </h2>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <!--<iframe src="/film.mp4" width="570" height="350" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe> -->
          <video width="570" height="350" controls>
            <source src="/vid/outputFilm.webm" type="video/webm">
          </video>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="content">
            <h2 v-html="$t('_ABOUT.TITLE')"></h2>
            <div class="text">
              <p> {{ $t('_ABOUT.TEXT1') }} </p>
            </div>
            <div class="text">
              <p> {{ $t('_ABOUT.TEXT2') }} </p>
            </div>

          </div>
        </div>

        <div class="post-area">
          <div class="content-box">
            <div class="text">
              <p> {{ $t('_ABOUT._PARAG_ONE') }} </p>
              <p> {{ $t('_ABOUT._PARAG_TWO') }} </p>
            </div>

            <div class="lower-content" style="padding-top: 24px;padding-bottom: 24px;">
              <h4> {{ $t('_ABOUT._TITLE_ONE') }} </h4>
            </div>

            <div class="text">
              <p> {{ $t('_ABOUT._PARAG_THREE') }} </p>
              <p> {{ $t('_ABOUT._PARAG_FOUR') }} </p>
              <p> {{ $t('_ABOUT._PARAG_FIVE') }} </p>
              <p> {{ $t('_ABOUT._PARAG_SIX') }} </p>
            </div>

            <div class="lower-content" style="padding-top: 24px;padding-bottom: 24px;">
              <h4> {{ $t('_ABOUT._TITLE_TWO') }} </h4>
            </div>

            <div class="text">
              <p> {{ $t('_ABOUT._PARAG_SEVEN') }} </p>
              <p> {{ $t('_ABOUT._PARAG_EIGHT') }} </p>
              <p> {{ $t('_ABOUT._PARAG_NINE') }} </p>
            </div>

          </div>

        </div>

      </div>
    </div>

  </section>

  <section class="why-chooseus style-2 sec-padd-top" style="background-image: url(/assets/images/background/9.jpg);">
    <div class="container image-wraper-three" >

      <div class="col-md-8 col-md-offset-4 col-sm-12">
        <div class="section-title">
          <h2> {{ $t('_FACTS.TITLE') }} </h2>
          <p v-html="$t('_FACTS.TEXT')"> </p>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="item">
              <div class="inner-box">
                <!--icon-box-->
                <div class="icon_box">
                  <span class="icon-people-1"></span>
                </div>
                <a href="#">
                  <h4> 120+ {{ $t('_FACTS.WORKERS.TITLE') }} </h4>
                </a>
              </div>
              <div class="text">
                <p>{{ $t('_FACTS.WORKERS.DISCRIPTION') }} </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="item">
              <div class="inner-box">
                <!--icon-box-->
                <div class="icon_box">
                  <span class="icon-people-1"></span>
                </div>
                <a href="#">
                  <h4> 10000 + {{ $t('_FACTS.DIRECT_WORKS.TITLE') }} </h4>
                </a>
              </div>
              <div class="text">
                <p>{{ $t('_FACTS.DIRECT_WORKS.DISCRIPTION') }}  </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="item">
              <div class="inner-box">
                <!--icon-box-->
                <div class="icon_box">
                  <span class="icon-people-1"></span>
                </div>
                <a href="3">
                  <h4> 600+ {{ $t('_FACTS.TONESPERMONTH.TITLE') }} </h4>
                </a>
              </div>
              <div class="text">
                <p>{{ $t('_FACTS.TONESPERMONTH.DISCRIPTION') }} </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="item">
              <div class="inner-box">
                <!--icon-box-->
                <div class="icon_box">
                  <span class="icon-people-1"></span>
                </div>
                <a href="#">
                  <h4> 20+ {{ $t('_FACTS.MILLIONBOUTLES.TITLE') }} </h4>
                </a>
              </div>
              <div class="text">
                <p> {{ $t('_FACTS.MILLIONBOUTLES.DISCRIPTION') }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="call-out">
    <div class="container">
      <div class="float_left">
      </div>

    </div>
  </section>

  <section class="home-google-map">

    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12869.693121923856!2d1.9364539!3d36.2535013!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x954b5da30c8c6ac7!2sAFC%20RECYCLAGE!5e0!3m2!1sfr!2sdz!4v1610981480991!5m2!1sfr!2sdz" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

  </section>

  <footer-top />
  <footer-bottom />

  <!-- Scroll Top  -->
  <button class="scroll-top tran3s color2_bg"><span class="fa fa-angle-up"></span></button>
  <!-- preloader  -->
  <!-- <div class="preloader"></div> -->
</div>
</template>

<script>
export default {
  name: 'about',
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      '/assets/js/custom.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
</script>

<style scoped>
.image-wraper-three{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px ;
}
.image-wraper-three h2,.image-wraper-three h4 {
  color: #8DC63F;

}
.image-wraper-three p{
  color : aliceblue;
}
p {
  font-size: 16px;
  padding: 8px;
  text-align: justify;
  text-justify: inter-word;
}
</style>
